import { OpenIdConfiguration } from 'angular-auth-oidc-client/lib/config/openid-configuration';
import { LogLevel } from 'angular-auth-oidc-client';
import Environment from './environment.d';

export const environment: Environment = {
  production: false,
  api: 'http://localhost:8080/api/v1/subscription',
  availableNodesApi: 'https://api.irulast-dev.com/v1/iriga/available-nodes',
  walletConnectDappApi: 'https://api.irulast-dev.com/v1/wallet-connect',
  openidconfig: {
    authority: 'https://auth.irulast-dev.com/realms/iriga',
    redirectUrl: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    clientId: 'iriga-local',
    scope: 'openid profile email offline_access irulast-local',
    responseType: 'code',
    silentRenew: true,
    useRefreshToken: true,
    renewTimeBeforeTokenExpiresInSeconds: 30,
    ignoreNonceAfterRefresh: true,
    logLevel: LogLevel.Debug,
    secureRoutes: [
      'http://localhost:8080/api/v1/subscription',
      'https://api.irulast-dev.com',
    ],
  } as OpenIdConfiguration,
};
