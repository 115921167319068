<header class="header header-6">
  <div class="branding">
    <a href="javascript:void(0)">
      <svg width="75%" height="75%" preserveAspectRatio="xMinYMid meet" viewBox="0 0 386 116" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M208.113 39.9782H208.055C208.073 40.6246 207.956 41.2678 207.711 41.8661C207.465 42.4645 207.098 43.0049 206.631 43.4525C206.173 43.9041 205.63 44.2605 205.034 44.5011C204.438 44.7416 203.799 44.8615 203.156 44.8538C202.491 44.8704 201.83 44.755 201.21 44.5145C200.589 44.274 200.023 43.9131 199.543 43.4525C199.063 43.0136 198.684 42.4755 198.432 41.8757C198.18 41.2758 198.061 40.6284 198.084 39.9782C198.084 39.3545 198.219 38.7382 198.481 38.1723C198.743 37.6063 199.125 37.1041 199.601 36.7007C200.562 35.7942 201.835 35.2924 203.156 35.2994C204.45 35.2853 205.698 35.7802 206.631 36.6775C207.095 37.0935 207.466 37.6022 207.722 38.1707C207.977 38.7392 208.11 39.3549 208.113 39.9782V39.9782ZM198.709 80.6281V48.085H207.418V80.6281H198.709Z" fill="white"/>
        <path d="M224.605 54.6982C225.777 52.5618 227.514 50.7888 229.626 49.5735C231.738 48.3581 234.144 47.7471 236.58 47.8074V57.0723H235.549C232.721 56.973 229.95 57.8937 227.744 59.6665C225.671 61.4037 224.64 64.299 224.64 68.2366V80.7211H215.908V48.178H224.64L224.605 54.6982Z" fill="white"/>
        <path d="M265.393 48.1779H274.045V80.6746H265.336V75.88C261.714 79.5474 257.908 81.3849 253.916 81.3927C252.272 81.4511 250.635 81.1425 249.125 80.4895C247.614 79.8365 246.268 78.8553 245.184 77.6172C242.972 75.1157 241.872 71.7108 241.872 67.4258V48.2126H250.604V66.0939C250.546 67.0808 250.685 68.0693 251.013 69.0019C251.342 69.9344 251.852 70.7923 252.515 71.5255C253.175 72.1793 253.964 72.6887 254.831 73.0217C255.698 73.3546 256.625 73.5037 257.553 73.4596C259.631 73.523 261.651 72.7706 263.181 71.3634C263.907 70.6779 264.478 69.8468 264.86 68.9247C265.241 68.0026 265.423 67.0102 265.393 66.0129V48.1779Z" fill="white"/>
        <path d="M282.106 80.686V36.2028H290.838V80.686H282.106Z" fill="white"/>
        <path d="M319.305 80.686L319.235 77.038C317.189 79.9101 313.858 81.3346 309.241 81.3114C306.051 81.4809 302.9 80.5531 300.312 78.6825C299.198 77.7865 298.313 76.6391 297.729 75.3344C297.145 74.0298 296.879 72.6053 296.953 71.1779C296.903 69.6623 297.244 68.1594 297.941 66.813C298.639 65.4666 299.67 64.3217 300.937 63.488C303.659 61.6169 306.899 60.645 310.202 60.7085C313.182 60.7088 316.146 61.1378 319.004 61.9825C319.104 57.2805 316.676 54.9295 311.719 54.9295C309.673 54.9399 307.636 55.1926 305.65 55.6823C303.978 56.0586 302.345 56.5938 300.775 57.2805L298.644 50.9224C303.11 48.7453 308.012 47.6089 312.981 47.5986C318.015 47.5986 321.737 48.8301 324.146 51.293C326.554 53.7482 327.759 57.5005 327.759 62.5152V80.744L319.305 80.686ZM311.754 75.0228C313.584 75.0769 315.387 74.5676 316.919 73.5636C317.603 73.0916 318.156 72.452 318.523 71.7059C318.889 70.9598 319.059 70.1321 319.015 69.3018V67.1477C316.909 66.4109 314.691 66.0427 312.46 66.059C310.764 65.9985 309.083 66.3987 307.596 67.2171C306.999 67.5294 306.5 68.0009 306.155 68.5794C305.809 69.158 305.631 69.8209 305.639 70.4946C305.626 71.1468 305.778 71.7917 306.082 72.3689C306.386 72.9462 306.831 73.4369 307.376 73.7952C308.668 74.6588 310.201 75.0887 311.754 75.0228V75.0228Z" fill="white"/>
        <path d="M347.921 81.5431H348.084C342.853 81.7094 337.709 80.1776 333.422 77.177L336.549 71.4907C338.212 72.5327 340.013 73.3358 341.899 73.8764C343.772 74.4789 345.723 74.7989 347.69 74.826C351.095 74.826 352.786 73.8764 352.786 71.9655C352.778 71.487 352.642 71.0195 352.391 70.6119C352.141 70.2043 351.785 69.8718 351.361 69.6492C350.275 69.0133 349.075 68.5962 347.829 68.4216C346.567 68.2132 345.038 67.8889 343.266 67.4372C341.691 67.034 340.144 66.5311 338.633 65.9317C337.2 65.3319 335.987 64.3047 335.159 62.9901C333.93 60.9482 333.486 58.5283 333.913 56.1833C334.339 53.8383 335.606 51.7291 337.475 50.2508C340.215 48.3803 343.483 47.4385 346.798 47.5639C351.234 47.4309 355.606 48.642 359.341 51.0383L356.596 56.8289C353.664 55.1161 350.332 54.2091 346.937 54.2C343.764 54.2 342.177 55.1689 342.177 57.1068C342.177 58.1491 342.826 58.9366 344.111 59.4231C345.629 60.0104 347.205 60.4336 348.813 60.6854C350.684 61.0068 352.535 61.4282 354.361 61.9478C356.212 62.4692 357.855 63.5539 359.063 65.0515C360.584 67.1274 361.264 69.7022 360.965 72.2586C360.667 74.815 359.412 77.1637 357.453 78.8331C355.137 80.6398 351.905 81.5431 347.921 81.5431Z" fill="white"/>
        <path d="M377.591 55.9604V69.9852C377.591 72.618 378.916 73.9344 381.564 73.9344C382.072 73.929 382.58 73.8825 383.081 73.7954C383.473 73.7389 383.86 73.6537 384.239 73.5406L384.633 73.3785L385.999 79.8987C383.709 80.9659 381.192 81.455 378.669 81.3232C376.074 81.4951 373.506 80.708 371.453 79.1112C370.572 78.3042 369.883 77.3101 369.436 76.2019C368.989 75.0937 368.796 73.8994 368.871 72.7068V55.9604H365.396V52.7177L377.14 40.9049H377.591V48.1779H384.54V55.9604H377.591Z" fill="white"/>
        <path d="M57.9175 115.835C89.9044 115.835 115.835 89.9044 115.835 57.9175C115.835 25.9305 89.9044 0 57.9175 0C25.9305 0 0 25.9305 0 57.9175C0 89.9044 25.9305 115.835 57.9175 115.835Z" fill="#70C5CA"/>
        <path d="M87.2295 115.835C119.216 115.835 145.147 89.9045 145.147 57.9176C145.147 25.9307 119.216 0.00012207 87.2295 0.00012207C55.2425 0.00012207 29.312 25.9307 29.312 57.9176C29.312 89.9045 55.2425 115.835 87.2295 115.835Z" fill="#4A79BC"/>
        <path d="M116.553 115.835C148.54 115.835 174.471 89.9045 174.471 57.9176C174.471 25.9307 148.54 0.00012207 116.553 0.00012207C84.5663 0.00012207 58.6357 25.9307 58.6357 57.9176C58.6357 89.9045 84.5663 115.835 116.553 115.835Z" fill="#3F4D98"/>
        <path d="M99.1924 36.1912C99.1673 37.1634 98.8562 38.1067 98.2978 38.903C97.7394 39.6993 96.9586 40.3132 96.0531 40.6681C95.1475 41.0229 94.1575 41.1028 93.2067 40.8979C92.256 40.6929 91.3868 40.2122 90.7079 39.5158C90.029 38.8194 89.5705 37.9382 89.3898 36.9826C89.2091 36.027 89.3142 35.0393 89.6919 34.1431C90.0697 33.2469 90.7033 32.4819 91.5136 31.944C92.3238 31.406 93.2747 31.1189 94.2472 31.1186C94.9121 31.1018 95.5733 31.2225 96.1893 31.4732C96.8054 31.7238 97.363 32.099 97.8273 32.5752C98.2915 33.0515 98.6524 33.6185 98.8873 34.2407C99.1222 34.8629 99.2261 35.5269 99.1924 36.1912V36.1912ZM98.8334 46.9848V84.7163H89.6727V46.9848H98.8334Z" fill="white"/>
        <path d="M128.145 46.7534C127.3 46.6839 126.431 46.626 125.505 46.626C119.367 46.626 115.881 49.1623 115.313 51.2584V46.9618H106.141V84.6933H115.313V66.1634C115.313 59.9559 118.973 55.3813 125.181 55.3003C126.184 55.294 127.183 55.4345 128.145 55.7172V46.7534Z" fill="white"/>
      </svg>
    </a>
  </div>
  <div class="header-actions">
    <clr-dropdown>
      <button class="nav-text" clrDropdownTrigger>
        <cds-icon shape="user"></cds-icon>
        <span class="nav-icon-text" *ngIf="isLoggedIn">{{ userName }}</span>
        <cds-icon shape="angle" direction="down"></cds-icon>
      </button>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <a href="javascript:void(0);" *ngIf="!isLoggedIn" (click)="login()" clrDropdownItem>Login</a>
        <a href="{{ accountManagementUrl() }}" *ngIf="isLoggedIn" clrDropdownItem>Account Management</a>
        <a href="{{ adminPortalUrl() }}" *ngIf="isLoggedIn" clrDropdownItem>Admin Portal</a>
        <a href="javascript:void(0);" *ngIf="isLoggedIn" (click)="logout()" clrDropdownItem>Logout</a>
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>
</header>
