import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AppRoutingModule} from "./app-routing.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {LoggerModule, NgxLoggerLevel} from "ngx-logger";
import {IrulastCommonModule} from "../../../../libs/irulast-common/src/lib/irulast-common.module";
import {AbstractSecurityStorage, AuthModule, DefaultLocalStorageService, LogLevel, AuthInterceptor} from "angular-auth-oidc-client";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";

import {environment} from "../../../../libs/environment/src/lib/environment";


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LoggerModule.forRoot({level: NgxLoggerLevel.INFO}),
    IrulastCommonModule,
    AuthModule.forRoot({
      config: environment.openidconfig,
    }),
    FontAwesomeModule,
    FlexLayoutModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: AbstractSecurityStorage,
      useClass: DefaultLocalStorageService
    },
   {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    }
  ]
})
export class AppModule {}
