<div class="alert alert-app-level alert-info" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="info-circle"></cds-icon>
      </div>
      <div class="alert-text">App Level Alert Bar</div>
      <div class="alert-actions">
        <button class="btn btn-sm alert-action">Action</button>
      </div>
    </div>
  </div>
  <button type="button" class="close" aria-label="Close">
    <cds-icon aria-hidden="true" shape="window-close"></cds-icon>
  </button>
</div>
