import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../header/header.component';
import { ClarityModule } from '@clr/angular';
import { AlertComponent } from '../alert/alert.component';
import { angleIcon, ClarityIcons, userIcon } from '@cds/core/icon';
import { CdsIconModule } from '@cds/angular';
import { DefaultLayoutComponent } from '../default-layout/default-layout.component';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

ClarityIcons.addIcons(userIcon, angleIcon);

@NgModule({
  declarations: [
    AlertComponent,
    HeaderComponent,
    DefaultLayoutComponent,
  ],
  exports: [
    AlertComponent,
    HeaderComponent,
    DefaultLayoutComponent,
    CdsIconModule,
    ClarityModule,
    RouterLink,
    RouterLinkActive,
    RouterOutlet,
  ],
  imports: [
    CommonModule,
    CdsIconModule,
    ClarityModule,
    RouterLink,
    RouterLinkActive,
    RouterOutlet,
  ],
})
export class IrulastCommonModule {}
