import {Component, OnInit} from '@angular/core';
import {LoginResponse, OidcSecurityService, PublicEventsService} from "angular-auth-oidc-client";
import {environment} from "environment";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isLoggedIn = false;
  userName: string = '';

  constructor(private oidcSecurityService: OidcSecurityService,
              private eventService: PublicEventsService) {
    // Add
    //  - CheckingAuth/CheckingAuthFinished/CheckingAuthFinishedWithError for a spinner on the user button
    //  - Either UserDataChanged/TokenExpired/IdTokenExpired/SilentRenewStarted/SilentRenewFailed for showing timed out
    // https://angular-auth-oidc-client.com/docs/documentation/public-events
/*
    this.eventService
      .registerForEvents()
      .pipe()
*/
    //this.meta.removeTag('http-equiv=Content-Security-Policy');
    //this.meta.addTag({ 'http-equiv': 'Content-Security-Policy', content: environment.contentSecurityPolicy});
  }

  ngOnInit() {
    this.oidcSecurityService.isAuthenticated$.subscribe(({isAuthenticated}) => {
      this.isLoggedIn = isAuthenticated;
    });
    this.oidcSecurityService.userData$.subscribe(({userData}) => {
      if (userData && userData !== '') {
        this.userName = userData.preferred_username;
      }
    });
  }

  login() {
    this.oidcSecurityService.authorize()
  }

  loginWithPopUp() {
    this.oidcSecurityService.authorizeWithPopUp().subscribe((loginResponse: LoginResponse) => {
      const { isAuthenticated, userData } = loginResponse;
      console.log(loginResponse);
      this.isLoggedIn = isAuthenticated;
      this.userName = userData?.preferred_username;
    });
  }

  logout() {
    this.oidcSecurityService.logoffAndRevokeTokens()
      .subscribe((result) => console.log('logout', result));
  }

  accountManagementUrl() {
    return `${environment.openidconfig.authority}/account?referrer=${environment.openidconfig.clientId}&referrer_uri=${window.location.origin}`;
  }

  adminPortalUrl() {
    return `${environment.openidconfig.authority}/portal`;
  }
}
