<app-alert></app-alert>
<div class="main-container">
  <app-header></app-header>
  <nav *ngIf="navItems != undefined" class="subnav">
    <ul class="nav">
      <ng-container [ngTemplateOutlet]="navItems"></ng-container>
    </ul>
  </nav>
  <router-outlet></router-outlet>
</div>
