import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";

const routes: Routes = [{
  path: '',
  redirectTo: 'iriga/dashboard',
  pathMatch: 'full'
},
  {
    path: 'iriga',
    loadChildren: () => import('./iriga/iriga.module').then(mod => mod.IrigaModule)
  },
  {
    path: 'baryon',
    loadChildren: () => import('./baryon/baryon.module').then(mod => mod.BaryonModule)
  }];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
