import {Component, OnInit} from '@angular/core';
import {AuthenticatedResult, OidcSecurityService} from "angular-auth-oidc-client";
import {Observable} from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'iriga-customer-frontend';
  isAuthenticated$: Observable<AuthenticatedResult>;

  constructor(private oidcSecurityService: OidcSecurityService) {
    this.isAuthenticated$ = this.oidcSecurityService.isAuthenticated$;
  }

  ngOnInit(): void {
    this.oidcSecurityService
      .checkAuth()
      .subscribe((result) =>
        console.log('app authenticated', result
        ));
  }

}
